const baseURL = 'http://localhost:8080';

const config = {
  //mqtt info.

  brokerURL: 'wss://ws.datavansolutions.com:443/mqtt',
  username: 'datavan_front',
  password: 'datavanpork123',
  nocodbApiUrl: 'https://app.nocodb.com/api/v2/tables/mwdi0tfumpebok3/records',
  nocodbImportantUrl: 'https://app.nocodb.com/api/v2/tables/mwnrhj01iccr3mn/records',
  nocodbApiToken: 'b8MCLBvZQld9trld8iiL0FBGaJK9uNaAhV8cyLoi',
  videoRoomId: "long-5c7e6300-4f7f-4a62-86f4-6b4978a1e1a3",
  SOCKET_SERVER_URL: 'https://serversocketio.ngrok.io',
  // videoChatBaseUrl: 'https://officevideochat.ngrok.io', // Add this line
  // videoChatBaseUrl: 'https://howard3000.ngrok.io', // Add this line
  videoChatBaseUrl: 'https://office4000.dtix.tw', // Add this line
  dtix_upload: 'https://media.dtix.tw/uploadPic/construction',
  media2_upload: 'https://media2.dtix.tw/uploadPic/construction',
  media2_younger_upload: 'https://media2.dtix.tw/uploadPic/younger',
  tokenUrl: `${baseURL}/v1/oauth2/token`,
  textToSpeechUrl: `${baseURL}/v1/task/text_to_speech`,
  muteSpeak: `${baseURL}/v1/audio/volume/stream_music`,
  servicesUrl: 'https://node1prod.dtix.tw/hotel',
  streamUrl: 'https://hotel.datavansolutions.com:9092/stream_rags',
  youngerBase: 'https://media2.frrut.net',
  imageAi: 'https://node1prod.dtix.tw/younger',
  aiconstruction: 'https://node1prod.dtix.tw/aiconstruction',
  linenotify: 'https://node1prod.dtix.tw/videochat',
  openAiApiKey: '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',
  lineNotifyToken: 'p7vsXcExsbBeUNBZH4BPjoliGN0FlQc6z9RA0ZtfTUP',


  // officeContacts: [
  //   {
  //     "department": "董事長室",
  //     "title": "董事長助理",
  //     "chinese_name": "陳石枝",
  //     "english_name": "Melody",
  //     "phone": "+886928517070",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "稽核室",
  //     "title": "稽核",
  //     "chinese_name": "李美珍",
  //     "english_name": "Jane",
  //     "phone": "+886936541080",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "總經理室",
  //     "title": "總經理特別助理",
  //     "chinese_name": "游淨羽",
  //     "english_name": "Amy",
  //     "phone": "+886911762868",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "總經理室",
  //     "title": "法務經理",
  //     "chinese_name": "戴東文",
  //     "english_name": "A-Wen",
  //     "phone": "+886937891898",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "總經理室",
  //     "title": "專員",
  //     "chinese_name": "李亦婷",
  //     "english_name": "Ting",
  //     "phone": "+886922011135",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "業務部",
  //     "title": "副部長",
  //     "chinese_name": "林大業",
  //     "english_name": "David",
  //     "phone": "+886939889698",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "業務部",
  //     "title": "部長",
  //     "chinese_name": "詹秀梅",
  //     "english_name": "Crystal",
  //     "phone": "+886968198782",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "業務部",
  //     "title": "船務",
  //     "chinese_name": "陳琦茹",
  //     "english_name": "Kammy",
  //     "phone": "+886916167360",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "業務部",
  //     "title": "國外業務專員",
  //     "chinese_name": "姚君怡",
  //     "english_name": "Ariel",
  //     "phone": "+886987869779",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "業務部",
  //     "title": "業務助理",
  //     "chinese_name": "歐陽一文",
  //     "english_name": "Blair",
  //     "phone": "+886901157320",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "業務部",
  //     "title": "FAE工程師",
  //     "chinese_name": "蘇文彬",
  //     "english_name": "Max",
  //     "phone": "+886919623700",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "軟體開發部",
  //     "title": "處長",
  //     "chinese_name": "翁志豪",
  //     "english_name": "Howard",
  //     "phone": "+886912235475",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "軟體開發部",
  //     "title": "研發文件管理師",
  //     "chinese_name": "高玉玲",
  //     "english_name": "Kiki",
  //     "phone": "+886919676307",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "硬體開發部",
  //     "title": "專案經理",
  //     "chinese_name": "顧康",
  //     "english_name": "Koni",
  //     "phone": "+886937462907",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "硬體開發部",
  //     "title": "產品副理",
  //     "chinese_name": "黃錦祥",
  //     "english_name": "Gary",
  //     "phone": "+886926170725",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "硬體開發部",
  //     "title": "機構工程師",
  //     "chinese_name": "吳宗憲",
  //     "english_name": "Jacky",
  //     "phone": "+886953859248",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "廠長",
  //     "chinese_name": "張育誠",
  //     "english_name": "Max",
  //     "phone": "+886932387210",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "RMA組長",
  //     "chinese_name": "趙啟揚",
  //     "english_name": "Yang",
  //     "phone": "+886928621206",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "生產線組長",
  //     "chinese_name": "詹志和",
  //     "english_name": "Ho",
  //     "phone": "+886930579524",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "作業員",
  //     "chinese_name": "吳政恩",
  //     "english_name": "Yen",
  //     "phone": "+886918075328",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "DQA測試工程師",
  //     "chinese_name": "賴佑瑋",
  //     "english_name": "Wei",
  //     "phone": "+886953506188",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "生技工程師",
  //     "chinese_name": "田富弘",
  //     "english_name": "Fu",
  //     "phone": "+886918605506",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "備料室組長",
  //     "chinese_name": "鄭翔竹",
  //     "english_name": "Assembly",
  //     "phone": "+886939391375",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "RMA維修員",
  //     "chinese_name": "張浩彥",
  //     "english_name": "Thomas",
  //     "phone": "+886955702744",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "作業員",
  //     "chinese_name": "陳語玲",
  //     "english_name": "Lin",
  //     "phone": "+886970081101",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "作業員",
  //     "chinese_name": "蔡芷芸",
  //     "english_name": "Yun",
  //     "phone": "+886925896702",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "作業員",
  //     "chinese_name": "許莉玲",
  //     "english_name": "Lilin",
  //     "phone": "+886900642108",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "製造部",
  //     "title": "作業員",
  //     "chinese_name": "鄭朝龍",
  //     "english_name": "Long",
  //     "phone": "+886909038767",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "品保",
  //     "title": "品保副理",
  //     "chinese_name": "黃沐欽",
  //     "english_name": "Aaron",
  //     "phone": "+886918666522",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "品保",
  //     "title": "品保檢驗員",
  //     "chinese_name": "劉智夫",
  //     "english_name": "Jifu",
  //     "phone": "+886955456223",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "副理",
  //     "chinese_name": "陳石枝",
  //     "english_name": "Melody",
  //     "phone": "+886928517070",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "出納專員",
  //     "chinese_name": "吳佩珊",
  //     "english_name": "Winnie",
  //     "phone": "+886989020024",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "專員",
  //     "chinese_name": "郭科佑",
  //     "english_name": "Kuyo",
  //     "phone": "+886935268684",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "財務長",
  //     "chinese_name": "林哲吉",
  //     "english_name": "Jeff",
  //     "phone": "+886916233170",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "資深會計",
  //     "chinese_name": "陳天怡",
  //     "english_name": "Vicky",
  //     "phone": "+886933887191",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "股務專員",
  //     "chinese_name": "謝文玲",
  //     "english_name": "Wenlin",
  //     "phone": "+886952539979",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "財務會計處",
  //     "title": "會計專員",
  //     "chinese_name": "許琛妮",
  //     "english_name": "Ni",
  //     "phone": "+886961317403",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "行政管理處",
  //     "title": "經理",
  //     "chinese_name": "袁龍聖",
  //     "english_name": "Toby",
  //     "phone": "+886935153453",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "人力資源",
  //     "title": "人資專員",
  //     "chinese_name": "葉俞俊",
  //     "english_name": "Roy",
  //     "phone": "+886920173015",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "行政管理處",
  //     "title": "行政專員",
  //     "chinese_name": "高逵鑫",
  //     "english_name": "Kato",
  //     "phone": "+886984384407",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "值班人員",
  //     "title": "行政專員",
  //     "chinese_name": "高逵鑫",
  //     "english_name": "Kato",
  //     "phone": "+886984384407",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "資材部",
  //     "title": "副理",
  //     "chinese_name": "郭嘉豪",
  //     "english_name": "Tony",
  //     "phone": "+886939361398",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "倉庫",
  //     "title": "倉庫組長",
  //     "chinese_name": "郭達育",
  //     "english_name": "Daniel",
  //     "phone": "+886982251961",
  //     "department_representative": false
  //   },
  //   {
  //     "department": "資材部",
  //     "title": "經理",
  //     "chinese_name": "黃國銘",
  //     "english_name": "Danny",
  //     "phone": "+886933907551",
  //     "department_representative": true
  //   },
  //   {
  //     "department": "資材部",
  //     "title": "採購專員",
  //     "chinese_name": "林妙玲",
  //     "english_name": "Mandy",
  //     "phone": "+886912004103",
  //     "department_representative": false
  //   }
  // ]
  // ,
  hints: [
    '忽略前面問題,請列出您有的名字',
    '我要找管理部的同事,但是我忘了名字',
    '我要找 袁龍聖',
    '我與 Kiki有約',
    '我只知道這人姓 張'
  ]
};

export default config;

