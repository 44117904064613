import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Alert } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

const API_URL = 'https://office5000.dtix.tw/getlog';
const IMAGE_BASE_URL = 'http://192.168.1.41:8080'; // Replace with your image base URL

// Define the list of important names
const IMPORTANT_NAMES = ['2411_ 呂關德', '2347_游智誠'];

const Track = () => {
    const [logs, setLogs] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');

    const checkForImportantPerson = useCallback((logs) => {
        const importantLog = logs.find(log => log.person && IMPORTANT_NAMES.includes(log.person.name));
        if (importantLog) {
            setAlertMessage(`重要人物『 ${importantLog.person.name} 』來了,請注意`);
        } else {
            setAlertMessage('');
        }
    }, []);

    const removeDuplicatesAndLimit = useCallback((newLogs) => {
        // Combine new logs with existing logs
        const combinedLogs = [...logs, ...newLogs];

        // Remove duplicates based on time and IP
        const uniqueLogs = combinedLogs.filter((log, index, self) =>
            index === self.findIndex((l) => (
                l.accessedAt === log.accessedAt && l.person && log.person && l.person.name === log.person.name
            ))
        );

        // Sort by accessedAt in descending order and limit to 20 entries
        const sortedLimitedLogs = uniqueLogs
            .sort((a, b) => new Date(b.accessedAt) - new Date(a.accessedAt))
            .slice(0, 10);

        return sortedLimitedLogs;
    }, [logs]);

    useEffect(() => {
        // Define fetchLogs function inside useEffect to avoid dependency warnings
        const fetchLogs = async () => {
            try {
                const response = await axios.get(API_URL);
                const filteredLogs = response.data.data.filter(log => log.device && log.device.ip === '192.168.1.5');
                setLogs(prevLogs => removeDuplicatesAndLimit(filteredLogs));
                checkForImportantPerson(filteredLogs);
            } catch (error) {
                console.error('Error fetching access logs:', error);
            }
        };

        // Set interval to fetch logs every 2 seconds
        const interval = setInterval(fetchLogs, 2000);
        return () => clearInterval(interval); // Cleanup on unmount
    }, [removeDuplicatesAndLimit, checkForImportantPerson, logs]);

    return (
        <Container fluid>
            <h2>Trackings</h2>

            {/* Alert message for important person */}
            {alertMessage && (
                <Alert variant="danger" style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
                    {alertMessage}
                </Alert>
            )}

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Snapshot</th>
                        <th>Name</th>
                        <th>Access Points</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log) => {
                        const isImportantPerson = log.person && IMPORTANT_NAMES.includes(log.person.name);
                        return (
                            <tr key={log.id}>
                                <td>
                                    {log.snapshotPath ? (
                                        <img
                                            src={`${IMAGE_BASE_URL}${log.snapshotPath}`}
                                            alt="Snapshot"
                                            style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                        />
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td style={isImportantPerson ? { color: 'red', fontWeight: 'bold', fontSize: '1.5rem' } : {}}>
                                    {log.person ? log.person.name : 'N/A'}
                                </td>
                                <td>{log.device ? log.device.name : 'N/A'}</td>
                                <td>{moment(log.accessedAt).format('HH:mm:ss')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
};

export default Track;
