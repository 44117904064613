import React, { useEffect, useState, useRef } from 'react';
import { Table, Container, Alert, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
// import moment from 'moment';
import mqtt from 'mqtt';
import config from './config'; // Assuming config.js exports the required variables
import { FaSyncAlt } from 'react-icons/fa';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

const pageId = sessionStorage.getItem('pageId') || uuidv4();
sessionStorage.setItem('pageId', pageId);


const IMAGE_BASE_URL = 'http://192.168.1.41:8080'; // Replace with your image base URL

const Track = () => {
    const [logs, setLogs] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [isMuted, setIsMuted] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [importantNames, setImportantNames] = useState([]);

    // const [serviceRequest, setServiceRequest] = useState(null);
    const [serviceRequests, setServiceRequests] = useState([]);

    const audioRef = useRef(null);
    const beepAudioRef = useRef(null);

    // Topics for publishing and subscribing

    const serviceSubscribeTopic = 'service/track/in';
    const peopleSubscribeTopic = 'service/people/in';
    // Define the publish topic for service
    const servicePublishTopic = 'service/track/out';
    



    const [currentTime, setCurrentTime] = useState("");


    useEffect(() => {
        const updateClock = () => {
            const now = new Date();
            const taiwanTime = now.toLocaleTimeString('zh-TW', {
                timeZone: 'Asia/Taipei',
                hour12: false // Ensures 24-hour format
            });
            setCurrentTime(taiwanTime);
        };

        // Update the time every second
        const timer = setInterval(updateClock, 1000);

        // Cleanup the timer on component unmount
        return () => clearInterval(timer);
    }, []);
    



    useEffect(() => {
        const mqttClient = mqtt.connect(config.brokerURL, {
            username: config.username,
            password: config.password,
            reconnectPeriod: 1000 // Reconnect every 1 second
        });

        const updateBroadcastTopic = 'service/track/updates'; // Moved here, local to useEffect

        mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');
            mqttClient.subscribe(serviceSubscribeTopic); // Subscribe to service request topic
            mqttClient.subscribe(peopleSubscribeTopic); // Subscribe to people checking in topic
            mqttClient.subscribe(updateBroadcastTopic); // Subscribe to updates from other users
        });

        mqttClient.on('error', (err) => {
            console.error('Connection error:', err);
        });

        mqttClient.on('message', (topic, message) => {
            if (topic === serviceSubscribeTopic) {
                const serviceMsg = JSON.parse(message.toString());
                setServiceRequests((prevRequests) => {
                    const exists = prevRequests.some(req => req.timestamp === serviceMsg.timestamp);
                    return exists ? prevRequests : [...prevRequests, serviceMsg];
                });
                if (beepAudioRef.current) {
                    beepAudioRef.current.loop = true;
                    beepAudioRef.current.play();
                }
            } else if (topic === peopleSubscribeTopic) {
                const checkInMsg = JSON.parse(message.toString());
                console.log(`Received check-in message on topic ${topic}:`, checkInMsg);

                // Filter logs for the specific device
                const filteredLogs = checkInMsg.data.filter(
                    log => log.device && log.device.ip === '192.168.1.5'
                );

                // Filter logs to keep only the latest log for each unique person
                const uniqueLogs = Array.from(
                    filteredLogs
                        .sort((a, b) => new Date(b.accessedAt) - new Date(a.accessedAt)) // Sort by latest time first
                        .reduce((map, log) => {
                            if (log.person && log.person.name) {
                                map.set(log.person.name, log); // Keep only the latest log for each person
                            }
                            return map;
                        }, new Map())
                        .values()
                );

                setLogs(uniqueLogs);

                // Identify important people in the logs
                const importantPeople = uniqueLogs.filter(
                    log => log.person && importantNames.includes(log.person.name)
                );
                if (importantPeople.length > 0) {
                    const importantNamesString = importantPeople
                        .map(log => log.person.name)
                        .filter(name => name.trim() !== '')
                        .join(', ')
                        .replace(/(, )+$/, ''); // Remove trailing commas, if any
                    setAlertMessage(`重要人物進入: ${importantNamesString}`);
                    setShowAlert(true);
                    if (audioRef.current && !isMuted) {
                        audioRef.current.play();
                    }
                } else {
                    setShowAlert(false);
                    setIsMuted(false); // Reset mute when alert disappears
                }
            } else if (topic === updateBroadcastTopic) {
                const updatedRequests = JSON.parse(message.toString());
                setServiceRequests(updatedRequests); // Update the local state with the broadcasted data

                // Stop the sound if the updated request list is empty
                if (updatedRequests.length === 0 && beepAudioRef.current) {
                    beepAudioRef.current.pause();
                    beepAudioRef.current.currentTime = 0;
                    beepAudioRef.current.loop = false;
                }
            }
        });

        return () => {
            if (mqttClient) mqttClient.end();
        };
    }, [isMuted, importantNames]);


    const fetchImportantNames = async () => {
        const allImportantNames = [];
        let currentPage = 1;
        const pageSize = 100;

        const fetchPage = async (page) => {
            try {
                const response = await axios.get(
                    `${config.nocodbImportantUrl}?page=${page}&limit=${pageSize}`,
                    {
                        headers: {
                            'xc-token': config.nocodbApiToken,
                        },
                    }
                );
                const names = response.data.list.map(record => record.important);
                console.log(names);
                allImportantNames.push(...names);

                if (response.data.meta && response.data.meta.page < response.data.meta.totalPages) {
                    await fetchPage(page + 1);
                }
            } catch (error) {
                console.error('Error fetching important names:', error);
            }
        };

        await fetchPage(currentPage);
        setImportantNames(allImportantNames);
    };

    useEffect(() => {
        fetchImportantNames();
    }, []);

    const handleMuteToggle = () => {
        setIsMuted(!isMuted);
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleAcknowledgeRequest = (timestamp) => {
        const requestToAcknowledge = serviceRequests.find(req => req.timestamp === timestamp);
        if (requestToAcknowledge) {
            const updatedPayload = { ...requestToAcknowledge, state: 'received' };

            const mqttClient = mqtt.connect(config.brokerURL, {
                username: config.username,
                password: config.password,
                reconnectPeriod: 1000,
            });

            mqttClient.on('connect', () => {
                mqttClient.publish(servicePublishTopic, JSON.stringify(updatedPayload), {}, (error) => {
                    if (error) {
                        console.error('Error publishing to MQTT:', error);
                    }

                    // Update local state
                    const updatedRequests = serviceRequests.filter(req => req.timestamp !== timestamp);
                    setServiceRequests(updatedRequests);

                    // Broadcast updated list
                    mqttClient.publish('service/track/updates', JSON.stringify(updatedRequests), {}, (error) => {
                        if (error) {
                            console.error('Error broadcasting updates:', error);
                        }
                        mqttClient.end();
                    });

                    // Stop the sound if no more service requests remain
                    if (updatedRequests.length === 0 && beepAudioRef.current) {
                        beepAudioRef.current.pause();
                        beepAudioRef.current.currentTime = 0;
                        beepAudioRef.current.loop = false;
                    }
                });
            });
        }
    };





    return (
        <Container fluid>
            <h2 style={{ marginTop: '20px' }}>值班人員管理頁面</h2>

            <div
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    backgroundColor: '#ccc', // Lighter grey background
                    color: '#555', // Dark grey text
                    padding: '10px 20px', // Padding around the text
                    borderRadius: '8px', // Rounded corners
                    fontSize: '1.5rem', // Font size for better readability
                    fontWeight: 'bold', // Make the font bold
                    zIndex: 1050, // Ensure it stays on top
                }}
            >
                {currentTime}
            </div>




            {/* Display important names at the top, centered, small font, dark grey */}
            <div style={{ textAlign: 'center', color: 'darkgrey', fontSize: '0.9rem', marginBottom: '20px' }}>
                {importantNames.length > 0 ? `重要人物列表: ${importantNames.filter(name => name && typeof name === 'string' && name.trim() !== '').join(' | ')}` : ''}
            </div>

            {/* Modal for start button */}
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Body className="d-flex justify-content-center align-items-center" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.95)', // 90% transparent white background
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1050,
                    overflow: 'hidden',
                }}>
                    <Button variant="primary" onClick={handleModalClose}>
                        值班機器啟用
                    </Button>
                </Modal.Body>
            </Modal>

            {/* Fixed section for alert message */}
            <div
                style={{
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                {showAlert && alertMessage ? (
                    <Alert
                        variant="danger"
                        style={{
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {alertMessage}
                        <Button
                            variant={isMuted ? 'secondary' : 'primary'}
                            onClick={handleMuteToggle}
                            style={{
                                marginLeft: '40px',
                                fontSize: '1.5rem',
                                width: '160px',
                                height: '50px',
                                animation: 'buttonPulse 1.2s infinite',
                                backgroundColor: isMuted ? '#d3d3d3' : '#8B0000', // Dark red when not muted
                                borderColor: isMuted ? '#d3d3d3' : '#8B0000', // Match border with background
                                color: '#f8f9fa', // Text color for contrast
                            }}
                        >
                            {isMuted ? '撥放' : '聲音停止'}
                        </Button>
                    </Alert>
                ) : (
                    <div
                        style={{
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#6c757d',
                        }}
                    >
                        目前沒有重要人物刷臉進大門
                    </div>
                )}
            </div>

            {/* Service Request Section */}
            <div
                style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center', // Center the entire section
                    width: '100%', // Full width for alignment purposes
                }}
            >
                <div
                    style={{
                        maxWidth: '80%', // Restrict the width to 70% of the page
                        width: '100%', // Make sure it fills the container
                    }}
                >
                    {serviceRequests.length > 0 ? (
                        serviceRequests.map((req) => (
                            <Alert
                                key={req.timestamp}
                                variant="info"
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: '#17a2b8',
                                    color: '#fff',
                                    borderRadius: '10px',
                                    padding: '20px',
                                    marginBottom: '10px',
                                    animation: 'slideIn 0.3s ease-in-out', // Optional: Add a slide-in effect
                                }}
                            >
                                <div>
                                    <span style={{ color: '#d3d3d3', fontWeight: 'normal' }}>人員:</span>&nbsp;&nbsp;{req.who}&nbsp;&nbsp;
                                    <span style={{ color: '#d3d3d3', fontWeight: 'normal' }}>要求:</span>&nbsp;&nbsp;{req.what}&nbsp;&nbsp;
                                    <span style={{ color: '#d3d3d3', fontWeight: 'normal' }}>地點:</span>&nbsp;&nbsp;{req.where}&nbsp;&nbsp;
                                    <span style={{ color: '#d3d3d3', fontWeight: 'normal' }}>緊急性:</span>&nbsp;&nbsp;{req.when}
                                </div>

                                <Button
                                    variant="success"
                                    onClick={() => handleAcknowledgeRequest(req.timestamp)}
                                    style={{
                                        fontSize: '1.2rem',
                                        padding: '10px 20px',
                                        backgroundColor: '#8B0000',
                                        borderColor: '#8B0000',
                                        color: '#fff',
                                        animation: 'buttonPulse 1.2s infinite', // Add the pulse effect
                                    }}
                                >
                                    確認收到
                                </Button>
                            </Alert>
                        ))
                    ) : (
                        <div
                            style={{
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: '#6c757d',
                            }}
                        >
                            目前沒有服務請求
                        </div>
                    )}
                </div>
            </div>

            <style>
                {`
        @keyframes slideIn {
            from {
                opacity: 0;
                transform: translateY(-20px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes buttonPulse {
            0%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
        }
    `}
            </style>



            <style>
                {`
                    @keyframes slideIn {
                        from {
                            opacity: 0;
                            transform: translateY(-30px);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    @keyframes pulseGlow {
                        0% {
                            box-shadow: 0 0 15px rgba(23, 162, 184, 0.6);
                        }
                        50% {
                            box-shadow: 0 0 30px rgba(23, 162, 184, 1);
                        }
                        100% {
                            box-shadow: 0 0 15px rgba(23, 162, 184, 0.6);
                        }
                    }

                    @keyframes buttonPulse {
                        0%, 100% {
                            transform: scale(1);
                        }
                        50% {
                            transform: scale(1.1);
                        }
                    }
                    `}
            </style>

            {/* Audio element for playing sound */}
            <audio ref={audioRef} src="/slot.mp3" />
            <audio ref={beepAudioRef} src="/slot.mp3" />





            <div
                style={{
                    maxHeight: '50vh', // Limit height to 50% of the viewport height
                    overflowY: 'auto', // Enable vertical scrolling
                    width: '100%', // Ensure the table spans 100% of the width
                }}
            >
                <Table
                    striped
                    bordered
                    hover
                    style={{
                        width: '100%', // Ensure the table spans 100% of the width
                        tableLayout: 'fixed', // Ensure consistent column widths
                    }}
                >
                    <thead>
                        <tr>
                            <th>快照</th>
                            <th>姓名</th>
                            <th>進入點</th>
                            <th>時間</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(
                            logs
                                .sort((a, b) => new Date(b.accessedAt) - new Date(a.accessedAt)) // Sort logs by time, latest first
                                .reduce((map, log) => {
                                    if (log.person && log.person.name) {
                                        // Use the person's name as the unique key
                                        map.set(log.person.name, log);
                                    }
                                    return map;
                                }, new Map())
                                .values()
                        ).map((log) => {
                            const isImportantPerson =
                                log.person && importantNames.includes(log.person.name);
                            return (
                                <tr key={log.id}>
                                    <td>
                                        {log.snapshotPath ? (
                                            <img
                                                src={`${IMAGE_BASE_URL}${log.snapshotPath}`}
                                                alt="Snapshot"
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '5px',
                                                }}
                                            />
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td
                                        style={
                                            isImportantPerson
                                                ? {
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                    fontSize: '1.5rem',
                                                }
                                                : {}
                                        }
                                    >
                                        {log.person ? log.person.name : 'N/A'}
                                    </td>
                                    <td>{log.device ? log.device.name : 'N/A'}</td>
                                    <td>
                                        {moment(log.accessedAt)
                                            .tz('Asia/Taipei')
                                            .format('HH:mm:ss')}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>







            {/* Reload Page Button */}
            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000, // Ensure the button stays on top
                }}
            >
                <Button
                    onClick={() => window.location.reload()}
                    style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#6c757d', // Low-key gray color
                        borderColor: '#6c757d', // Match border with background
                        color: '#fff', // White icon for contrast
                        borderRadius: '50%', // Make the button round
                        display: 'flex',
                        flexDirection: 'column', // Stack content vertically
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                        textAlign: 'center', // Center the text
                    }}
                >
                    <FaSyncAlt style={{ fontSize: '1.5rem' }} /> {/* Reload Icon */}
                    <div style={{ fontSize: '1.8rem', marginTop: '2px' }}>重置</div> {/* Text below the icon */}
                </Button>

            </div>
        </Container>
    );
};

export default Track;
