// import React, { useEffect, useState } from "react";
import React, { useEffect, useState, useRef } from "react"; // Add useRef

import { Button, Container, Alert } from "react-bootstrap";
import mqtt from "mqtt";
import axios from "axios";
import config from "./config"; // Assuming config.js contains NocoDB and MQTT configurations
import './Request.css';
import { FaSyncAlt } from 'react-icons/fa';
import { useLocation } from "react-router-dom";
import queryString from "query-string";




const serviceSubscribeTopic = "service/track/out";
const servicePublishTopic = "service/track/in";

const Request = () => {
    const beepAudioRef = useRef(null);
    const [data, setData] = useState({ who: [], what: [], when: [], where: [] });
    const [selectedOptions, setSelectedOptions] = useState({
        who: "",
        what: "",
        when: "",
        where: "",
    });
    const [alertMessage, setAlertMessage] = useState("");
    const [mqttClient, setMqttClient] = useState(null);
    const [buttonStatus, setButtonStatus] = useState("發送請求");
    const [isPublishing, setIsPublishing] = useState(false);

    const [clickedButton, setClickedButton] = useState({});
    const [currentRequestTimestamp, setCurrentRequestTimestamp] = useState(null);

    const slotAudioRef = useRef(null);
    const doneAudioRef = useRef(null);

    const currentLocation = useLocation(); // Renamed location to

    const [generatedLink, setGeneratedLink] = useState("");


    useEffect(() => {
        const params = queryString.parse(currentLocation.search); // Parse query parameters
        const initialSelections = { who: "", what: "", when: "", where: "" };

        Object.keys(initialSelections).forEach((key) => {
            if (params[key]) {
                if (key === "who") {
                    // Directly use the 'who' value from the URL
                    initialSelections[key] = params[key];
                    setClickedButton((prev) => ({
                        ...prev,
                        [key]: params[key], // Set the 'who' button as clicked
                    }));
                } else if (data[key]?.includes(params[key])) {
                    // If the value exists in the list, select it
                    initialSelections[key] = params[key];
                    setClickedButton((prev) => ({
                        ...prev,
                        [key]: params[key], // Set the button as clicked
                    }));
                } else {
                    // If the value is not in the list, treat it as "自訂"
                    initialSelections[key] = "自訂";
                    setSelectedOptions((prev) => ({
                        ...prev,
                        [`${key}Other`]: params[key], // Set "自訂" value
                    }));
                }
            }
        });

        setSelectedOptions((prev) => ({
            ...prev,
            ...initialSelections,
        }));
    }, [currentLocation.search, data]);



    const handleButtonClick = (type, value) => {
        setClickedButton((prev) => ({
            ...prev,
            [type]: value,
        }));

        if (value === "自訂") {
            // Reset the custom input field to blank when "自訂" is selected
            setSelectedOptions((prev) => ({
                ...prev,
                [`${type}Other`]: "",
            }));
        }

        handleSelection(type, value);
    };



    useEffect(() => {
        // Connect to the MQTT broker
        const client = mqtt.connect(config.brokerURL, {
            username: config.username,
            password: config.password,
        });

        client.on("connect", () => {
            console.log("Connected to MQTT broker");
            client.subscribe(serviceSubscribeTopic);
        });

        client.on("message", (topic, message) => {
            if (topic === serviceSubscribeTopic) {
                const response = JSON.parse(message.toString());
                console.log("Response received:", response);

                if (response.timestamp === currentRequestTimestamp) {
                    if (slotAudioRef.current) {
                        slotAudioRef.current.pause();
                        slotAudioRef.current.currentTime = 0;
                    }
                    if (doneAudioRef.current) {
                        doneAudioRef.current.play();
                    }

                    setIsPublishing(false);

                    // Stop beep sound
                    if (beepAudioRef.current) {
                        beepAudioRef.current.pause();
                        beepAudioRef.current.currentTime = 0;
                    }

                    let countdown = 5; // Start countdown at 5
                    setButtonStatus(`值班人員收到 (${countdown})`);

                    const interval = setInterval(() => {
                        countdown -= 1;
                        if (countdown > 0) {
                            setButtonStatus(`值班人員收到 (${countdown})`);
                        } else {
                            clearInterval(interval);
                            setButtonStatus("發送請求");
                        }
                    }, 1000); // Update every second
                } else {
                    console.warn("Received response with unmatched timestamp:", response.timestamp);
                }
            }
        });





        client.on("error", (err) => {
            console.error("MQTT Connection Error:", err);
        });

        setMqttClient(client);

        return () => {
            if (client) client.end();
        };
    }, [currentRequestTimestamp]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(config.nocodbImportantUrl, {
                    headers: { "xc-token": config.nocodbApiToken },
                });

                // Extract values for each category
                const rawData = Array.isArray(response.data)
                    ? response.data
                    : response.data.list || [];

                const who = rawData
                    .map((record) => record.who?.trim())
                    .filter((value) => value);
                const what = rawData
                    .map((record) => record.what?.trim())
                    .filter((value) => value);
                const when = rawData
                    .map((record) => record.when?.trim())
                    .filter((value) => value);
                const where = rawData
                    .map((record) => record.where?.trim())
                    .filter((value) => value);

                setData({ who, what, when, where });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleSelection = (type, value) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [type]: value,
        }));
    };

    const handleSubmit = () => {
        const { who, what, when, where, whoOther, whatOther, whenOther, whereOther } = selectedOptions;

        // Use "自訂" values if selected, otherwise use the regular selection
        const payloadWho = who === "自訂" ? whoOther : who;
        const payloadWhat = what === "自訂" ? whatOther : what;
        const payloadWhen = when === "自訂" ? whenOther : when;
        const payloadWhere = where === "自訂" ? whereOther : where;

        // Check if "自訂" fields are empty when selected
        if (
            (who === "自訂" && !whoOther?.trim()) ||
            (what === "自訂" && !whatOther?.trim()) ||
            (when === "自訂" && !whenOther?.trim()) ||
            (where === "自訂" && !whereOther?.trim())
        ) {
            setAlertMessage("請填寫選擇'自訂'的內容");
            return;
        }

        // Check if all required fields are selected
        if (!payloadWho || !payloadWhat || !payloadWhen || !payloadWhere) {
            setAlertMessage("請選擇所有選項或填寫自訂內容");
            return;
        }

        const timestamp = Date.now(); // Generate a timestamp for the request
        setCurrentRequestTimestamp(timestamp); // Save the timestamp for validation

        const requestPayload = {
            who: payloadWho,
            what: payloadWhat,
            when: payloadWhen,
            where: payloadWhere,
            timestamp, // Include the timestamp in the payload
        };

        setButtonStatus("已發送,等候回應中..");
        setIsPublishing(true);

        // Play beep sound in loop
        if (beepAudioRef.current) {
            beepAudioRef.current.loop = true;
            beepAudioRef.current.play();
        }

        // Generate URL with parameters
        const baseUrl = window.location.origin + window.location.pathname;
        const urlParams = new URLSearchParams({
            who: payloadWho,
            what: payloadWhat,
            when: payloadWhen,
            where: payloadWhere,
        });
        setGeneratedLink(`${baseUrl}?${urlParams.toString()}`);

        if (mqttClient) {
            mqttClient.publish(
                servicePublishTopic,
                JSON.stringify(requestPayload),
                {},
                (err) => {
                    if (err) {
                        console.error("Error publishing to MQTT topic:", err);
                        setAlertMessage("請求發送失敗");
                        setButtonStatus("發送請求");
                        setIsPublishing(false);

                        // Stop beep sound if publishing fails
                        if (beepAudioRef.current) {
                            beepAudioRef.current.pause();
                            beepAudioRef.current.currentTime = 0;
                        }
                    } else {
                        console.log("Request sent:", requestPayload);
                    }
                }
            );
        }
    };



    useEffect(() => {
        const params = queryString.parse(currentLocation.search);
        const urlWhoValue = params.who;

        if (urlWhoValue) {
            setSelectedOptions((prev) => ({
                ...prev,
                who: urlWhoValue,
            }));
            setClickedButton((prev) => ({
                ...prev,
                who: urlWhoValue,
            }));
        }
    }, [currentLocation.search]);




    return (
        <Container style={{ padding: "20px" }}>
            <h1 style={{
                marginBottom: "40px",


            }}>VIP直撥值班人員服務</h1>

            <audio ref={beepAudioRef} src="/blip.mp3" />

            {/* Display alert message if any */}
            {alertMessage && (
                <Alert
                    variant="info"
                    onClose={() => setAlertMessage("")}
                    dismissible
                    style={{ marginTop: "20px" }}
                >
                    {alertMessage}
                </Alert>
            )}

            {Object.keys(data).map((key) => {
                const queryParams = queryString.parse(currentLocation.search);
                const urlWhoValue = key === "who" ? queryParams.who : null;

                // Filter data or include the `who` parameter value if it isn't in the list
                const filteredData =
                    key === "who" && urlWhoValue
                        ? data[key].includes(urlWhoValue)
                            ? data[key].filter((item) => item === urlWhoValue)
                            : [urlWhoValue] // Include the `who` parameter value as a button
                        : data[key];

                // Skip rendering "自訂" and others when `who` parameter exists
                const showCustomOption = !(key === "who" && urlWhoValue);

                return filteredData.length > 0 && (
                    <div
                        key={key}
                        style={{
                            marginBottom: "20px",
                            padding: "15px",
                            border: "1px solid #d3d3d3", // Light grey border
                            borderRadius: "10px", // Rounded corners
                            backgroundColor: "#D4EFDF", // Light green background color
                        }}
                    >
                        <h4 style={{ color: "#007bff", textAlign: "center" }}>
                            {{
                                who: "長官",
                                what: "需要任務",
                                when: "時間",
                                where: "地點",
                            }[key]}
                        </h4>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap", // Allow wrapping to new lines
                                justifyContent: "center", // Center items horizontally
                                gap: "10px", // Add spacing between buttons
                            }}
                        >
                            {filteredData.map((item, index) => (
                                <Button
                                    key={index}
                                    variant={
                                        clickedButton[key] === item ? "primary" : "secondary"
                                    }
                                    onClick={() => handleButtonClick(key, item)}
                                    style={{
                                        margin: "5px",
                                        padding: "10px 20px",
                                        fontSize:
                                            clickedButton[key] === item ? "1.5rem" : "1.3rem", // Larger font for clicked button
                                        transition: "font-size 0.2s ease",
                                        width: "calc(100% - 30px)",
                                        maxWidth: "180px",
                                        height: "70px",
                                        textAlign: "center",
                                        whiteSpace: "nowrap",
                                        borderRadius: "5px",
                                    }}
                                >
                                    {item}
                                </Button>
                            ))}


                            {/* Show "自訂" button only when allowed */}
                            {showCustomOption && (
                                <>
                                    <Button
                                        variant={
                                            selectedOptions[key] === "自訂" ? "primary" : "secondary"
                                        }
                                        onClick={() => handleButtonClick(key, "自訂")}
                                        style={{
                                            margin: "5px",
                                            padding: "10px 20px",
                                            fontSize: "1.4rem",
                                            width: "calc(100% - 30px)", // Occupy full row on smaller screens
                                            maxWidth: "180px", // Limit maximum width for larger screens
                                            height: "70px",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        自訂
                                    </Button>

                                    {/* Text area for custom input */}
                                    {selectedOptions[key] === "自訂" && (
                                        <textarea
                                            value={selectedOptions[`${key}Other`] || ""}
                                            onChange={(e) => {
                                                const value = e.target.value; // Extract the value immediately
                                                setSelectedOptions((prev) => ({
                                                    ...prev,
                                                    [`${key}Other`]: value, // Use the captured value
                                                }));
                                            }}
                                            placeholder={`請輸入${{
                                                who: "自訂長官",
                                                what: "自訂需要任務",
                                                when: "自訂時間",
                                                where: "自訂地點",
                                            }[key]}`}
                                            style={{
                                                marginTop: "10px",
                                                padding: "10px",
                                                width: "calc(100% - 30px)", // Occupy full row on smaller screens
                                                maxWidth: "180px", // Limit maximum width for larger screens
                                                fontSize: "1rem",
                                                border: "1px solid #d3d3d3",
                                                borderRadius: "5px",
                                                resize: "none", // Disable resizing
                                            }}
                                        ></textarea>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                );
            })}










            {/* Add keyframes dynamically */}
            <style>
                {`
                    @keyframes shake {
                        0%, 100% { transform: translateX(0); }
                        25% { transform: translateX(-5px); }
                        50% { transform: translateX(5px); }
                        75% { transform: translateX(-5px); }
                    }

                    @keyframes vibrant-gradient {
                        0% { background-position: 0% 50%; }
                        25% { background-position: 50% 0%; }
                        50% { background-position: 100% 50%; }
                        75% { background-position: 50% 100%; }
                        100% { background-position: 0% 50%; }
                    }
                `}
            </style>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column", // Stack elements vertically
                    alignItems: "center", // Center align text and button
                    marginTop: "20px",
                }}
            >
                <p
                    style={{
                        fontSize: "1.2rem", // Larger font size
                        color: "#ff8c00", // Dark orange color
                        marginBottom: "10px", // Space between text and button
                        fontWeight: "bold", // Make the text bold for emphasis
                        textAlign: "center", // Center align text
                    }}
                >
                    提交後無取消功能
                </p>


                <button
                    onClick={handleSubmit}
                    disabled={isPublishing}
                    style={{
                        backgroundColor: buttonStatus.startsWith("值班人員收到")
                            ? undefined
                            : buttonStatus === "已發送,等候回應中.."
                                ? undefined
                                : "#28a745", // Default green
                        backgroundImage: buttonStatus.startsWith("值班人員收到")
                            ? "linear-gradient(45deg, #ff8c00, #ffa500, #ff7f50, #ff4500)" // Orange gradient for countdown
                            : buttonStatus === "已發送,等候回應中.."
                                ? "linear-gradient(45deg, #007bff, #34c759, #28a745)" // Blue gradient for pending
                                : undefined,
                        backgroundSize: "300% 300%",
                        animation:
                            buttonStatus.startsWith("值班人員收到") ||
                                buttonStatus === "已發送,等候回應中.."
                                ? "shake 0.5s infinite, vibrant-gradient 5s ease infinite"
                                : "none",
                        color: "#fff",
                        padding: "20px 100px", // Match request button padding
                        borderRadius: "8px",
                        fontSize: "1.5rem", // Match request button font size
                        border: "none",
                        cursor: isPublishing ? "not-allowed" : "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "fit-content", // Automatically adjusts to padding
                        margin: "0 auto", // Center the button horizontally
                    }}
                >
                    {buttonStatus}
                </button>



            </div>


            {/* Display the generated link */}
            {generatedLink && (
                <div
                    style={{
                        marginTop: "140px", // Increased top margin
                        padding: "15px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                        textAlign: "center",
                        fontSize: "0.9rem", // Smaller font size for the entire section
                    }}
                >
                    <h4 style={{ color: "#007bff", fontSize: "1rem" }}>複製快捷連結下次使用</h4>
                    <p style={{ wordBreak: "break-all", fontSize: "0.9rem" }}>{generatedLink}</p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center", // Center the button horizontally
                            alignItems: "center", // Center content vertically if needed
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            onClick={() => {
                                if (navigator.clipboard && navigator.clipboard.writeText) {
                                    navigator.clipboard.writeText(generatedLink)
                                        .then(() => alert("連結已複製到剪貼簿！"))
                                        .catch((err) => console.error("Failed to copy text: ", err));
                                } else {
                                    // Fallback for older browsers
                                    const textArea = document.createElement("textarea");
                                    textArea.value = generatedLink;
                                    document.body.appendChild(textArea);
                                    textArea.select();
                                    try {
                                        document.execCommand("copy");
                                        alert("連結已複製到剪貼簿！");
                                    } catch (err) {
                                        console.error("Failed to copy text: ", err);
                                    }
                                    document.body.removeChild(textArea);
                                }
                            }}
                            style={{
                                backgroundColor: "#555", // Dark grey background color
                                color: "#fff", // White text for contrast
                                borderRadius: "5px", // Rounded corners but still rectangular
                                padding: "0", // Remove default padding for precise size control
                                width: "120px", // Set a fixed width
                                height: "40px", // Set a fixed height
                                fontSize: "0.8rem", // Slightly smaller text size for the button
                                textAlign: "center", // Center text
                            }}
                        >
                            複製連結
                        </Button>
                    </div>
                </div>
            )}


            {/* Reload Page Button */}
            <div
                style={{
                    position: 'fixed',
                    top: '10px', // Distance from the top edge of the screen
                    right: '10px', // Distance from the right edge of the screen
                    zIndex: 1000, // Ensure the button stays on top of other elements
                }}
            >
                <Button
                    onClick={() => window.location.reload()}
                    style={{
                        width: '80px',
                        height: '80px',
                        backgroundColor: '#d3d3d3', // Light grey background color
                        borderColor: '#d3d3d3', // Match border with background
                        color: '#fff', // White text and icon color
                        borderRadius: '50%', // Make the button round
                        display: 'flex',
                        flexDirection: 'column', // Stack content vertically
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 'none', // No shadow for simplicity
                        textAlign: 'center', // Center the text
                        borderWidth: '2px', // Define border width
                        borderStyle: 'solid', // Solid border style
                        transition: 'background-color 0.3s, color 0.3s', // Smooth hover transition
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#555'; // Dark grey background on hover
                        e.target.style.color = '#fff'; // White text on hover
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#d3d3d3'; // Revert to light grey background
                        e.target.style.color = '#fff'; // Revert to white text
                    }}
                >
                    <FaSyncAlt style={{ fontSize: '1.5rem' }} /> {/* Reload Icon */}
                    <div style={{ fontSize: '1rem', marginTop: '2px' }}>重置</div> {/* Text below the icon */}
                </Button>
            </div>


        </Container >
    );
};

export default Request;
